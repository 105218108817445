.MonthBlock {
  line-height: 35px;
}

.activeScrollMonth .MonthBlock {
  background-color: #ffffff;
  height: 100%;
  width: 100%;
}

.CalendarFieldBlock {
  /*position: relative;*/
}

#scrollContainerElement::-webkit-scrollbar {
  display: none;
}


